<template>
  <div class="site-wrapper">
    <div class="a-main a-main--home bg-linear a-main--login">
      <div class="container">
        <div class="logo">
             <LogoSvg />
            <h1 class="a-logo-title">Health Cards</h1>
        </div>
        <!-- <div class="logo">
             
            </div> -->
            <!-- <div class="login-text">Sign in</div> -->
            <div class="col-md-12 login-form">
              <Error :errors="errors" />
              <form method="POST" class="my-5" @submit.prevent="login">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                    v-model="email"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <VuePassword
                    placeholder="Password"
                    v-model="password"
                    id="password"
                    type="password"
                    :disableStrength="true"
                    required
                  />
                </div>
                <button :disabled="loading" type="submit" class="btn btn-green btn-mt">
                  Login
                </button>
              </form>
              <div class="forgot-password-container">
                <div class="or">Or</div>
                <router-link class="forgot" :to="{ path: 'forgot-password' }">
                  <u>Forgot password ?</u> |
                </router-link>

                <router-link class="register" :to="{ path: 'register' }">
                  <u>Register</u></router-link
                >
              </div>
            </div>

      </div>
    </div>
  </div>
</template>



<script>
import VuePassword from "vue-password";
import { mapActions, mapState } from "vuex";
import Error from "@/components/Error.vue";
import LogoSvg from "@/components/svg/LogoSvg";
export default {
  components: {
    Error,
    VuePassword,
    LogoSvg,
  },
  props: [],
  data() {
    return {
      type: "password",
      email: null,
      password: null,
      errors: [],
    };
  },
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    ...mapActions(["setIsAuthenticated"]),
    ...mapActions("user", ["fetchUserInfoAction"]),
    ...mapActions(["setLoading"]),
    login() {
      if (this.checkForm()) {
        const clientId = process.env.VUE_APP_CLIENT_ID;
        const clientSecret = process.env.VUE_APP_CLIENT_SECRET;
        this.setLoading(true);
        // this.$auth.logout({}).then(() => {
        this.$auth
          .login({
            username: this.email,
            password: this.password,
            client_id: clientId,
            client_secret: clientSecret,
            grant_type: "password",
          })
          .then((res) => {
            this.setIsAuthenticated({
              auth: true,
              token: res.data.access_token,
            });
            this.setLoading(false);
            this.$router.push({ name: "Home" });
            this.fetchUserInfoAction();
          })
          .catch(() => {
            // this.$toaster.error("Invalid Credentials.");
            this.errors = [];
            this.errors.push("Invalid Credentials.");
            this.setLoading(false);
          });
        // });
      }
    },
    onPasswordChange(e) {
      this.strength = e.target.value.length;
    },
    checkForm() {
      if (this.email) {
        return true;
      }
      this.errors = [];
      if (!this.email) {
        this.errors.push("Email required.");
      }
      if (!this.password) {
        this.errors.push("Password required.");
      }
    },
  },
};
</script>

<style scoped>
.a-main--login{
  display: flex;
  align-items: flex-start;
  
}

.forgot-password-container{
  padding-bottom: 5rem;
}
.login-text {
  text-align: center;
  margin-top: 60px;
  font-size: 28px;
  /* color: rgb(4 188 197); */
}
.login-form {
  margin-top: 60px;
}
.btn-green {
  width: 100%;
}
.btn-mt{
  margin-top: 2rem;
}
.or {
  text-align: center;
  margin-top: -31px;
  margin-bottom: 10px;
  color: white;
}
.register {
  margin-top: -30px;
}
.forgot {
  margin-left: 79px;
}
.alert {
  border: 2px solid transparent;
}
.logo {
  text-align: center;
  margin-top: 60px;
}
.logo img {
  margin-top: 30px;
  max-width: 30%;
  max-height: 18vh;
}
label {
  color: white;
}
@media (min-width: 576px){
  .a-main--login{
    align-items: center;
  }
} 
@media (min-width: 1025px){
  .a-main--login{
    align-items: flex-start;
  }
  .logo{
    margin-top: 80px;
  }
} 
</style>
